import Image from 'next/image';
import { v4 as uuid } from 'uuid';
import { Button, Grid } from '@mui/material';

import { Icon } from '@components';
import { InfoSection } from '@components/product/info-section/InfoSection';
import { useCartData } from '@contexts/cart-data';
import { UAH } from '@lib/constants/common-constants';
import { ICountriesMap } from '@lib/countriesMap';
import { attributesToFilter, IAttribute, ICartItem, IProduct, IProductAttributesDictionary } from '@types';

import classes from './CombinedOffers.module.scss';

const CombinedOffers = () => {
  const { cart, setCart } = useCartData();
  // TODO replace with real one when back and will be ready
  const featuredProducts: { products: IProduct[] }[] = [];
  const offersSection = (featuredProducts && featuredProducts[0]) || { products: [], productType: { alias: 'all' } };
  const item = offersSection.products[0] as IProduct;

  if (!item) {
    return null;
  }

  const isInCart = cart && cart.length ? cart.find((i) => i.product.id === item.id) : false;

  const addToCartHandler = (ev: React.SyntheticEvent | React.MouseEvent) => {
    ev.stopPropagation();
    ev.preventDefault();
    if (!isInCart) {
      setCart((c: ICartItem[]) => [...c, { product: item, qty: 1, productId: item.id, id: uuid() }]);
    }
  };

  // TODO sort attributes by sortOrder ref. src/components/product/CatalogProductCard.tsx
  const productParams: IAttribute[] = [];
  Object.keys(item.attributes).forEach((attributeKey) => {
    const attribute = item.attributes[attributeKey as IProductAttributesDictionary];
    if (attribute?.value) {
      productParams.push(attribute);
    }
  });
  const otherAttrs = productParams.filter(({ code }) => !attributesToFilter.includes(code)).splice(0, 5);
  const countryAttr = item.attributes.country as IAttribute<ICountriesMap>;
  const imageLink =
    item.images && item.images[0].preview ? item.images[0].preview : '/assets/images/flower-no-image.svg';

  return (
    <div className={classes.combinedOffersWrapper}>
      <div className={classes.combinedOffersContainer}>
        <h1 className={classes.title}>Разом дешевше</h1>
        <Grid container justifyContent="space-between" alignItems="center" className={classes.combinedCardsWrapper}>
          <Grid item className={classes.combinedCardsContainer}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-evenly"
              className={classes.cardsWrapper}
              wrap="nowrap"
            >
              <Grid item className={classes.cardContainer}>
                <div className={classes.imageContainer}>
                  <Image className={classes.image} src={imageLink} alt={item.displayName} quality={100} fill />
                </div>
                <InfoSection name={item.displayName} attributes={otherAttrs} country={countryAttr?.value} />
                {/* {!item.discount ? (
                  <div className={classes.discountIcon}>
                    <p>-{item.discount}%</p>
                  </div>
                ) : null} */}
              </Grid>
              <Grid item className={classes.cardContainer}>
                <div className={classes.imageContainer}>
                  <Image className={classes.image} src={imageLink} alt={item.displayName} quality={100} fill />
                </div>
                <InfoSection name={item.displayName} attributes={otherAttrs} country={countryAttr?.value} />
              </Grid>
              <Icon className={classes.combinedIcon} size={45} type="combinedOffers" />
            </Grid>
            <Grid className={classes.combinedCardsFooter}>
              <div className={classes.cardPrice}>
                <span className={classes.priceWithoutDiscount}>
                  {item.price}
                  &nbsp;
                  {UAH}
                </span>
                <span className={classes.priceWithDiscount}>
                  {item.price}
                  &nbsp;
                  {UAH}
                </span>
              </div>
              <Button
                size="large"
                variant="contained"
                component="span"
                className={classes.addToCartBtn}
                onClick={addToCartHandler}
              >
                <span>В кошик</span>
              </Button>
            </Grid>
          </Grid>
          <Grid item className={classes.combinedCardsContainer}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-evenly"
              className={classes.cardsWrapper}
              wrap="nowrap"
            >
              <Grid item className={classes.cardContainer}>
                <div className={classes.imageContainer}>
                  <Image
                    className={classes.image}
                    src={imageLink}
                    alt={item.displayName}
                    width={100}
                    height={100}
                    quality={100}
                  />
                </div>
                <InfoSection name={item.displayName} attributes={otherAttrs} country={countryAttr?.value} />
                {/* {!item.discount ? (
                  <div className={classes.discountIcon}>
                    <p>-{item.discount}%</p>
                  </div>
                ) : null} */}
              </Grid>
              <Grid item className={classes.cardContainer}>
                <div className={classes.imageContainer}>
                  <Image
                    className={classes.image}
                    src={imageLink}
                    alt={item.displayName}
                    width={100}
                    height={100}
                    quality={100}
                  />
                </div>
                <InfoSection name={item.displayName} attributes={otherAttrs} country={countryAttr?.value} />
              </Grid>
              <Icon className={classes.combinedIcon} size={45} type="combinedOffers" />
            </Grid>
            <Grid className={classes.combinedCardsFooter}>
              <div>
                <span className={classes.priceWithoutDiscount}>
                  {item.price}
                  &nbsp;
                  {UAH}
                </span>
                <span className={classes.priceWithDiscount}>
                  {item.price}
                  &nbsp;
                  {UAH}
                </span>
              </div>
              <Button
                size="large"
                variant="contained"
                component="span"
                className={classes.addToCartBtn}
                onClick={addToCartHandler}
              >
                <span>В кошик</span>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export { CombinedOffers };
