import { FC } from 'react';
import clsx from 'clsx';

import { STATUS_LABELS } from '@lib/constants/common-constants';
import { Icon } from '../icon/Icon';

import classes from './ProductStockStatus.module.scss';

interface IProps {
  status: keyof typeof STATUS_LABELS;
  className?: string;
}

function getInStockStatus(status: keyof typeof STATUS_LABELS) {
  switch (status) {
    case 'preorder':
      return (
        <>
          <Icon type="calendarChecked" size={18} />
          {STATUS_LABELS.preorder}
        </>
      );
    case 'soldout':
      return (
        <>
          <Icon type="cancelRounded" size={18} />
          {STATUS_LABELS.soldout}
        </>
      );
    case 'limited':
      return (
        <>
          <Icon type="clockRounded" size={18} />
          {STATUS_LABELS.limited}
        </>
      );
    case 'instock':
      return (
        <>
          <Icon type="checkRounded" size={18} />
          {STATUS_LABELS.instock}
        </>
      );
    default:
      return STATUS_LABELS.default;
  }
}

const ProductStockStatus: FC<IProps> = ({ status, className }) => (
  <div className={clsx(classes.stockStatus, status, className)}>{getInStockStatus(status)}</div>
);

export default ProductStockStatus;
