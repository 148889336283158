import { FC } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { DialogTitle, Drawer } from '@mui/material';

import { ISystemOutlet } from '@types';
import { Icon } from '../../icon/Icon';

import classes from './ShopsMobile.module.scss';

interface IShopsMobileProps {
  selectedCities?: ISystemOutlet[];
  showBottomSheet: boolean;
  selectedShop?: string;
  closeBottomSheet: () => void;
}

const ShopsMobile: FC<IShopsMobileProps> = ({ selectedCities, selectedShop, closeBottomSheet, showBottomSheet }) => {
  return selectedCities && selectedCities.length ? (
    <Drawer
      open={showBottomSheet}
      anchor="bottom"
      onClose={closeBottomSheet}
      slotProps={{ backdrop: { invisible: true } }}
      classes={{
        paper: classes.mobileModal,
        root: classes.root
      }}
    >
      <DialogTitle className={classes.dialogCloseMark} id="scroll-dialog-title">
        <Icon type="cross" size={15} onClick={closeBottomSheet} leftOffset="auto" />
      </DialogTitle>
      <div className={classes.selectedCity}>
        {selectedCities.map((point: ISystemOutlet, index: number) => {
          if (point.outletId === selectedShop) {
            const image = point.image
              ? typeof point.image === 'string'
                ? point.image
                : point.image.url
              : point.gallery && (typeof point.gallery[0] === 'string' ? point.gallery[0] : point.gallery[0]?.url);
            const workingHours = point.workingHours
              ? `Пн-Нд: ${point?.workingHours?.start} - ${point?.workingHours?.end}`
              : '';
            return (
              <div className={classes.wrapperSelectedCity} key={index}>
                <Image
                  src={image || '/assets/images/flower-no-image.svg'}
                  alt={point.street || point.label}
                  className={classes.img}
                  quality={100}
                  width={720}
                  height={200}
                />
                <p className={classes.typeBuilding}>{point.name}</p>
                <p className={classes.street}>{point.address}</p>
                <div className={classes.infoWrapper}>
                  <a href={`tel:+${point.phone}`} className={classes.phone}>
                    +{point.phone}
                  </a>
                  <div className={classes.workHours}>{workingHours}</div>
                </div>
                <Link
                  href={
                    point.googleMapLink
                      ? point.googleMapLink
                      : `https://www.google.com/maps/search/?api=1&query=${point.latitude},${point.longitude}`
                  }
                  className={classes.link}
                  target="_blank"
                >
                  Прокласти маршрут
                </Link>
              </div>
            );
          }
          return null;
        })}
      </div>
    </Drawer>
  ) : null;
};

export { ShopsMobile };
