import { FC, ReactNode, SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import parse from 'autosuggest-highlight/parse';
import clsx from 'clsx';
import _debounce from 'lodash/debounce';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';

import { getLocation } from '@api/locations';
import { ButtonFPot } from '@components';
import { useDeliveryAddress } from '@contexts/delivery-address';
import { GOOGLE_MAPS_API_KEY } from '@lib/constants/environment';
import { IAutocompleteService, ILocation, IPlaceType, IRequestPayload } from '@types';
import { getAddressValue } from '@utils/formatting';

import classes from './PlacesAutocomplete.module.scss';

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

function getOptionLabel(option?: IPlaceType | string): string {
  if (typeof option === 'string') return option;
  if (option && 'structured_formatting' in option) {
    return option?.structured_formatting?.main_text;
  }
  return '';
}

const autocompleteService: { current: IAutocompleteService | null } = { current: null };

interface IProps {
  address?: ILocation;
  setAddress: (address: ILocation) => void;
  inputLabel?: string;
  withoutSuggestions?: boolean;
  withoutSubmit?: boolean;
  error?: boolean;
  classNameWrapper?: string;
  setToLocal?: boolean;
}

const suggestedCities = ['м. Київ'];
const cityWarningMessage = <p className={classes.warning}>Нажаль, нас поки що немає в вашому місті</p>;

const PlacesAutocomplete: FC<IProps> = ({
  address,
  setAddress,
  inputLabel,
  withoutSuggestions,
  classNameWrapper,
  withoutSubmit,
  error,
  setToLocal
}) => {
  const { setLastUsedDeliveryAddress, setPlaceToLocalAddress } = useDeliveryAddress();
  const [location, setLocation] = useState<ILocation>();
  const [value, setValue] = useState<IPlaceType | undefined>(getAddressValue(address));
  const [inputValue, setInputValue] = useState('');
  const [cityWarning, setCityWarning] = useState<string | ReactNode>('');
  const [options, setOptions] = useState<readonly IPlaceType[]>([]);
  const [loading, setLoading] = useState(false);
  const loaded = useRef(false);
  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  const fetchRequest = useMemo(
    () =>
      _debounce((request: IRequestPayload, callback: (results?: IPlaceType[] | null) => void) => {
        if (autocompleteService.current && autocompleteService.current.getPlacePredictions) {
          autocompleteService.current.getPlacePredictions(request, callback);
        }
      }, 400),
    []
  );

  const fetchLocation = (val?: ILocation | IPlaceType) => {
    if (val) {
      // workaround for back-end locations, remove ASAP
      if ('description' in val && val.description === 'Київ, Україна') {
        val.description = 'Київ (Київська область)';
      }

      getLocation(val)
        .then((res: ILocation) => {
          setLocation(res);
          if (!res.isCoveredByDeliveryNetwork) {
            setCityWarning(cityWarningMessage);
            if (withoutSubmit) {
              // ? TODO do we need to set the address to the localStorage on changing on checkout page
              setAddress(res);
            }
          } else {
            if (withoutSubmit) {
              // ? TODO do we need to set the address to the localStorage on changing on checkout page
              setAddress(res);
            }
            if (setToLocal) {
              setLastUsedDeliveryAddress(res);
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    if (inputValue.length < 3) return;

    let active = true;
    if (!autocompleteService.current && window.google && window.google.maps && window.google.maps.places) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) return;
    if (inputValue === '') {
      setOptions(value ? [value] : []);
      setLoading(false);
      return;
    }

    setCityWarning('');
    setLoading(true);
    fetchRequest(
      {
        input: inputValue,
        language: 'uk',
        componentRestrictions: { country: ['ua'] },
        types: ['locality']
      },
      (results?: IPlaceType[] | null) => {
        if (active) {
          if (results) {
            setOptions(results);
            if (suggestedCities.includes(inputValue)) {
              setValue(results[0]);
              fetchLocation(results[0]);
            }
          }
          setLoading(false);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [inputValue, fetchRequest]);

  // * trigger the location request on initial income address
  useEffect(() => {
    if (address && typeof address !== 'string' && address.city) {
      setTimeout(() => {
        setInputValue(`м. ${address.city}`);
      }, 200);
    }
  }, []);

  const setSuggestedCity = (c: string) => {
    setInputValue(c);
  };

  const onChange = (_event: SyntheticEvent, newValue: IPlaceType | null) => {
    if (newValue) {
      setOptions(newValue ? [newValue, ...options] : options);
      setValue(newValue);

      fetchLocation(newValue);
    }
  };

  const onInputChange = (event: SyntheticEvent, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const submitAddress = () => {
    if (location) {
      setPlaceToLocalAddress(value);
      setAddress(location);
    }
  };

  const isOptionEqualToValue = (option: IPlaceType, value: IPlaceType): boolean => {
    return option.description === value.description;
  };

  return (
    <>
      <Autocomplete
        getOptionLabel={getOptionLabel}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        openOnFocus
        classes={{
          popper: classes.popper,
          root: classNameWrapper,
          listbox: classes.listbox
        }}
        includeInputInList
        filterSelectedOptions
        value={value}
        noOptionsText="Немає результатів"
        onChange={onChange}
        onInputChange={onInputChange}
        isOptionEqualToValue={isOptionEqualToValue}
        renderInput={(params) => (
          <TextField
            label={inputLabel || 'Введіть адресу'}
            {...params}
            name="city"
            value={inputValue}
            InputProps={{
              ...params.InputProps,
              style: {
                height: 44,
                display: 'flex',
                alignItems: 'center',
                paddingTop: 2,
                borderRadius: 8,
                borderColor: '#ebe4e4'
              },
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
            variant="outlined"
            error={error}
            helperText={cityWarning}
          />
        )}
        renderOption={(props, option) => {
          const matches = option.structured_formatting.main_text_matched_substrings || [];
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length])
          );

          return (
            <li {...props} className={clsx(props.className, classes.optionItem)}>
              {parts.map((part, index) => (
                <span key={index} className={clsx({ [classes.itemBoldText]: part.highlight })}>
                  {part.text}
                </span>
              ))}
              <p className={classes.option}>{option.structured_formatting.secondary_text}</p>
            </li>
          );
        }}
      />
      {!withoutSuggestions ? (
        <p className={classes.suggestedCities}>
          {suggestedCities.map((c) => (
            <span key={c} onClick={() => setSuggestedCity(c)}>
              {c}
            </span>
          ))}
        </p>
      ) : null}
      {!withoutSubmit ? (
        <ButtonFPot
          onClick={submitAddress}
          disabled={!location?.isCoveredByDeliveryNetwork}
          fullWidth
          label="Застосувати"
        />
      ) : null}
    </>
  );
};

export { PlacesAutocomplete };