import FormControlLabel from '@mui/material/FormControlLabel';
import MaterialSwitch, { SwitchProps } from '@mui/material/Switch';

export interface IFlrSwitchProps extends SwitchProps {
  labelClassName?: string;
  label: string;
}

export const Switch = (props: React.PropsWithChildren<IFlrSwitchProps>) => {
  const { label, labelClassName, onChange, ...other } = props;

  const onChangeBoolean = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    return onChange && onChange(event, checked);
  };

  return (
    <FormControlLabel
      classes={{ label: labelClassName }}
      control={<MaterialSwitch {...other} onChange={onChangeBoolean} />}
      label={label}
    />
  );
};
