import { ChangeEvent, FC, FocusEvent } from 'react';

import classes from './TextInput.module.scss';

interface ITextInputProps {
  min?: number;
  max?: number;
  label: string;
  placeholder: string;
  value: string | number;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (event: FocusEvent<HTMLInputElement>) => void;
}

const TextInput: FC<ITextInputProps> = ({ min, max, label, placeholder, value, onChange, onBlur }) => (
  <div className={classes.container}>
    <input
      min={min}
      max={max}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      className={classes.inputField}
    />
    <label>{label}</label>
  </div>
);

export { TextInput };
