import { A11y, Navigation, Pagination, Scrollbar } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';

import { FC } from 'react';
import clsx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';

import { breakpoints } from '@lib/constants/breakpointsSwiper';
import { CarouselButtonArrows } from '@components/shared/carousel/CarouselButtonArrows';

import classes from './Carousel.module.scss';

interface IProduct {
  productCards: JSX.Element[];
}

const Carousel: FC<IProduct> = ({ productCards }) => {
  return (
    <Swiper
      className={classes.swiperWrapper}
      slidesPerView={4}
      spaceBetween={20}
      speed={500}
      slidesPerGroup={1}
      freeMode={false}
      modules={[Navigation, Pagination, A11y, Scrollbar]}
      breakpoints={breakpoints}
    >
      {productCards.slice(0, 20).map((item, index) => (
        <SwiperSlide key={index}>{item}</SwiperSlide>
      ))}
      <CarouselButtonArrows productCardsLength={productCards.length} />
      <div
        className={clsx('pagination', classes.customPagination)}
        style={{ width: 'max-content', margin: '15px auto 15px', overflowX: 'scroll' }}
      ></div>
    </Swiper>
  );
};

export default Carousel;
