import { FC } from 'react';

import classes from './SuccessFeedback.module.scss';

interface ISuccessFeedBackModalProps {
  successMessage?: string;
}

const SuccessFeedback: FC<ISuccessFeedBackModalProps> = ({ successMessage }) => {
  return <p className={classes.successMessage}>{successMessage}</p>;
};

export default SuccessFeedback;
