import { ChangeEvent, FC, isValidElement, ReactNode } from 'react';
import clsx from 'clsx';

import { Icon } from '@components';

import classes from './Checkbox.module.scss';

export interface ICheckboxProps {
  label?: ReactNode | string;
  onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  checked?: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  classNameIcon?: string;
  classNameLabel?: string;
}

function getIconType(checked: boolean | undefined, indeterminate: boolean | undefined) {
  if (indeterminate) {
    return 'checkboxInter';
  } else if (checked) {
    return 'checkboxChecked';
  } else {
    return 'checkbox';
  }
}

const Checkbox: FC<ICheckboxProps> = ({ label, checked, disabled, onChange, indeterminate, classNameIcon, classNameLabel }) => {
  const onChangeBoolean = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event, event.target.checked);
    }
  };

  const labelVal = (typeof label === 'string' || isValidElement(label)) && label;
  const ariaLabel = typeof label === 'string' ? label : undefined;

  return (
    <div className={classes.container}>
      <label className={clsx(classes.label, { [classes.disabled]: disabled, [classNameLabel || '']: !!classNameLabel })}>
        <input
          type="checkbox"
          onChange={onChangeBoolean}
          checked={checked}
          className={classes.input}
          disabled={disabled}
          onClick={(event)=>event.stopPropagation()}
          aria-label={ariaLabel}
        />
        <Icon
          size={30}
          type={getIconType(checked, indeterminate)}
          className={clsx(classes.icon, { [classes.checkboxChecked]: checked, [classNameIcon || '']: !!classNameIcon })}
        />
        {labelVal}
      </label>
    </div>
  );
};

export { Checkbox };
