import { Children, FC, ReactNode } from 'react';
import Link from 'next/link';
import clsx from 'clsx';
import MaterialExpansionPanel, { AccordionProps } from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Divider from '@mui/material/Divider';

import { Icon } from '../icon/Icon';

import classes from './ExpandPanel.module.scss';

interface IProps extends AccordionProps {
  label: ReactNode | undefined;
  actions?: ReactNode;
  leftIcon?: boolean;
  withDivider?: boolean;
  className?: string;
  classNameSummary?: string;
  classNameButton?: string;
  iconSize?: number;
  accordionDetailsClassName?: string;
}

export const ExpandPanel: FC<IProps> = ({
  label,
  children,
  leftIcon,
  withDivider,
  actions,
  defaultExpanded,
  className,
  classNameSummary,
  classNameButton,
  iconSize,
  accordionDetailsClassName,
  ...other
}) => {
  const hasChildren = Children.count(children) > 0;
  const defaultExpandedActual = defaultExpanded !== undefined && defaultExpanded;
  return (
    <div className={clsx(classes.root, className)}>
      {withDivider && <div className={classes.divider} />}
      <MaterialExpansionPanel
        className={clsx(classes.panel, { withDivider })}
        square
        defaultExpanded={defaultExpandedActual && hasChildren}
        disabled={!hasChildren}
        {...other}
      >
        <AccordionSummary
          className={clsx({ leftIcon, [classNameSummary || '']: !!classNameSummary }, classes.summary)}
          expandIcon={<Icon type="arrowExpand" size={iconSize || 12} />}
          classes={{
            expandIconWrapper: clsx(classes.smallIcon, {
              [classes.leftIcon]: leftIcon,
              [classNameButton || '']: classNameButton
            })
          }}
        >
          {typeof label === 'string' ? (
            <Link href="#" className={classes.heading}>
              {label}
            </Link>
          ) : (
            label
          )}
        </AccordionSummary>
        <AccordionDetails className={clsx(classes.details, accordionDetailsClassName)}>{children}</AccordionDetails>
        {actions && (
          <>
            <Divider />
            <AccordionActions>{actions}</AccordionActions>
          </>
        )}
      </MaterialExpansionPanel>
    </div>
  );
};
