'use client';

import { FC, ReactNode, useEffect, useRef } from 'react';
import ReactDom from 'react-dom';
import clsx from 'clsx';

import { Icon } from '../icon/Icon';

import modalClasses from './Drawer.module.scss';

interface IDrawerProps {
  open: boolean;
  closeIconClassName?: string;
  closeDrawer: () => void;
  children: ReactNode;
  classes?: { wrapper?: string };
}

const Drawer: FC<IDrawerProps> = ({ open, closeIconClassName, closeDrawer, classes, children, ...rest }) => {
  const drawerRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (open) {
      drawerRef.current = window.document.getElementById('drawer');
      if (drawerRef.current) {
        window.document.body.style.overflow = 'hidden';
        return () => {
          window.document.body.style.overflow = 'auto';
        };
      }
    }
  }, [open]);

  return open && drawerRef.current
    ? ReactDom.createPortal(
      <>
        <div className={modalClasses.wrapper} onClick={closeDrawer} {...rest}>
          <div className={modalClasses.modalContainer} onClick={(e) => e.stopPropagation()}>
            <div className={clsx(modalClasses.wrapperIcon, closeIconClassName)}>
              <Icon onClick={closeDrawer} type="cross" size={15} leftOffset="auto" />
            </div>
            <div className={clsx(modalClasses.container, { [classes?.wrapper || '']: classes?.wrapper })}>
              {children}
            </div>
          </div>
        </div>
      </>,
      drawerRef.current
    )
    : null;
};

export { Drawer };
