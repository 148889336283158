import { FC, ReactNode, useState } from 'react';
import clsx from 'clsx';

import { Modal } from '@components';
import SuccessFeedback from '@components/modals/success-feedback/SuccessFeedback';

import classes from './FeedbackModal.module.scss';

interface IFeedbackModalProps {
  subtitle?: ReactNode;
  successMessage?: string;
  buttonLabel: ReactNode;
  title?: string;
  successTitle?: string;
  form: ReactNode;
  classNameFeedbackBtn?: string;
  classNameSubtitleContainer?: string;
  successSentReview?: boolean;
}

const FeedbackModal: FC<IFeedbackModalProps> = ({
  subtitle,
  successMessage,
  buttonLabel,
  successTitle,
  title,
  form,
  classNameFeedbackBtn,
  successSentReview,
  classNameSubtitleContainer
}) => {
  const [isOpen, setIsOpen] = useState(false);

  function handleOpenModal() {
    setIsOpen(true);
  }

  function handleCloseModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Modal open={isOpen} closeModal={handleCloseModal}>
        <h3 className={classes.title}>{successSentReview ? successTitle : title}</h3>
        {successSentReview ? (
          <SuccessFeedback successMessage={successMessage} />
        ) : (
          <>
            <div className={classNameSubtitleContainer}>{subtitle ? subtitle : null}</div>
            {form || null}
          </>
        )}
      </Modal>
      {!successSentReview ? (
        <button
          onClick={handleOpenModal}
          className={clsx(classes.feedbackBtn, classNameFeedbackBtn)}
          aria-label="Залишити відгук"
        >
          {buttonLabel}
        </button>
      ) : null}
    </>
  );
};

export default FeedbackModal;
