import React, { FC, useEffect, useState } from 'react';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';
import clsx from 'clsx';
import { IconButton } from '@mui/material';
import MaterialPagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

import { filterStringToJson } from '@page-layouts/catalog/FiltersBar/utils';
import { Icon } from '../shared';

import classes from './Pagination.module.scss';

interface IProps {
  loadMoreTitle: string;
  totalPages: number;
  disabled?: boolean;
  onLoadMore?: (count: number) => void;
  className?: string;
}

const Pagination: FC<IProps> = ({ loadMoreTitle, onLoadMore, totalPages, disabled, className }) => {
  const pathName = usePathname();
  const [loadMoreCount, setLoadMoreCount] = useState(0);
  const [page, setPage] = useState(1);

  const searchParams = useSearchParams();
  const searchParamsStringified = searchParams.toString();

  useEffect(() => {
    const filterPrams = filterStringToJson(decodeURIComponent(searchParamsStringified), undefined);
    if (filterPrams.pageNumber) {
      setPage(Number(filterPrams.pageNumber) || 1);
    } else if (!filterPrams.pageNumber && page !== 1) {
      setPage(1);
    }
  }, [searchParams]);

  const onShowMore = (_e: React.SyntheticEvent | React.MouseEvent) => {
    if (onLoadMore) {
      const params = new URLSearchParams(searchParamsStringified);
      const newPage = page + loadMoreCount + 1;
      params.set('pageNumber', `${newPage}`);
      setPage(newPage);
      setLoadMoreCount(0);

      const url = params.toString();
      onLoadMore(newPage);
      // ! TODO workaround for nextjs app router (still in progress, ETA end of the 2023)
      // https://github.com/vercel/next.js/discussions/48110
      history.replaceState(null, '', `${pathName}?${decodeURIComponent(url)}`);
    }
  };

  const handleChange = () => {
    setLoadMoreCount(0);
  };

  const generateLink = (pageNumber: number | null) => {
    if (!pageNumber) return `${pathName}?${searchParams.toString()}`;
    const params = new URLSearchParams(searchParamsStringified);
    params.set('pageNumber', `${loadMoreCount > 0 ? page + loadMoreCount + 1 : pageNumber}`);
    const url = params.toString();
    return `${pathName}?${decodeURIComponent(url)}`;
  };

  const activePages = Array.from({ length: page + loadMoreCount - page + 1 }, (_, i) => page + i);

  return (
    <div className={clsx(classes.paginationContainer, className)}>
      {totalPages > 1 ? (
        <IconButton
          size="large"
          className={classes.showMoreBtn}
          onClick={onShowMore}
          disabled={disabled || loadMoreCount + 1 >= totalPages || page === totalPages}
          aria-label="Завантажити більше"
        >
          <>
            <Icon type="return" size={16} offset={10} className={classes.showMoreIcon} />
            <span>{loadMoreTitle}</span>
          </>
        </IconButton>
      ) : null}
      {totalPages > 1 ? (
        <MaterialPagination
          page={page}
          onChange={handleChange}
          count={totalPages}
          disabled={disabled}
          renderItem={(item) => (
            <PaginationItem
              component={(props) => <Link href={generateLink(item.page)} {...props} />}
              components={{
                previous: () => <Icon size={24} type="arrowForward" style={{ transform: 'rotate(180deg)' }} />,
                next: () => <Icon size={24} type="arrowForward" />
              }}
              classes={{ root: classes.item, selected: classes.itemSelected }}
              {...item}
              selected={activePages.includes(item.page || 0)}
            />
          )}
        />
      ) : null}
    </div>
  );
};

export { Pagination };
