import { FC, ReactElement, ReactNode } from 'react';
import clsx from 'clsx';

import classes from './MobileOnly.module.scss';

interface IProps {
  children: ReactNode | ReactElement;
  className?: string;
}

const MobileOnly: FC<IProps> = ({ children, className }) => (
  <div className={clsx(classes.root, className)}>{children}</div>
);

export { MobileOnly };
